import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import AboutHero from "../components/about-hero";
import CtaBanner from "../components/cta-banner";
import PhotoRight from "../components/photo-right";
import AboutPhotoRight from "../components/about-photo-right";
import PhotoLeft from "../components/photo-left";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const AboutPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Info-Panel-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whyARCImg: wpMediaItem(title: { eq: "Why-Choose-ARC" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whyGCImg: wpMediaItem(title: { eq: "Why-Good-Copy-Matters" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			antoinetteImg: wpMediaItem(title: { eq: "Antoinette-Main" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;
	const whyARCImg = data.whyARCImg.localFile.childImageSharp.gatsbyImageData;
	const whyGCImg = data.whyGCImg.localFile.childImageSharp.gatsbyImageData;
	const antoinetteImg =
		data.antoinetteImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "1",
				name: "About",
				item: {
					url: `${siteUrl}/about`,
					id: `${siteUrl}/about`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="About ARC Writing and Translation Services"
				description="I love helping my clients reach their target audience and convert more leads into sales by writing copy that talks directly to their customers."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about`,
					title: "About ARC Writing and Translation Services",
					description:
						"I love helping my clients reach their target audience and convert more leads into sales by writing copy that talks directly to their customers.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/CNoGBiUg-scaled-1.jpg`,
							width: 2560,
							height: 2316,
							alt: "About ARC",
						},
					],
				}}
			/>
			<AboutHero
				h="h-85"
				imgWidth="22rem"
				title="Professional copywriter & certified translator"
				description={
					<div className="bg-primary mw-100 mw-lg-70 p-md-4 mt-3 mt-md-4 py-3 px-4">
						<p className="mb-lg-0 fw-light text-white ">
							I love helping my clients reach their target audience and convert
							more leads into sales by writing copy that talks directly to their
							customers and presents them with the solutions they need.
						</p>
					</div>
				}
				image={heroImg}
				imgAlt={data.heroImg.altText}
			/>
			<section className="py-5 pt-lg-5 pt-lxl0 py-md-0">
				<PhotoRight
					imgWidth="30rem"
					image={whyARCImg}
					imgAlt={data.whyARCImg.altText}
					title="Company background"
					text={
						<>
							<p>
								I founded ARC Writing and Translation Services in 2020 to merge
								my translation and copywriting businesses under one umbrella and
								simplify and clarify my own messaging and brand.
							</p>
							<p>
								ARC Writing and Translation Services provides copywriting
								services to businesses in London, as well as nationally and
								internationally.
							</p>
						</>
					}
				/>
			</section>
			<PhotoLeft
				imgWidth="30rem"
				image={whyGCImg}
				imgAlt={data.whyGCImg.altText}
				title="Why choose ARC Writing and Translation Services?"
				text={
					<>
						<p>
							There are many benefits to choosing ARC Writing and Translation
							Services if you are looking for a London-based copywriter or a
							French-English, Spanish-English or Italian-English translator. I
							have been writing and translating into English professionally
							since 2007 and ALWAYS ensure I use the right words in the right
							voice for the target audience.
						</p>
						<p>
							I don’t just know one language but four. I am passionate about
							making sure that words fit on a page to deliver the intended
							message to sell your product or services via your website,
							brochure or other marketing materials or translate a document from
							French, Italian or Spanish to be read by an English-speaking
							audience.
						</p>
					</>
				}
			/>
			<section className="py-5 py-md-0">
				<AboutPhotoRight
					image={antoinetteImg}
					imgAlt={data.antoinetteImg.altText}
					title="A little bit about Antoinette Chappell"
					text={
						<>
							<p>
								My background is languages and I am passionate about words and
								how they can be used in copywriting and translation to get
								people’s messages across. My whole working life has involved one
								common thread: working with languages.
							</p>
							<p>
								Starting with an undergraduate degree in Modern Foreign
								Languages when I left school, and then after having taken a few
								years off to have my two children, working as a French and
								Spanish teacher in a secondary school to being awarded a
								Master’s degree with distinction in translation in 2007. In
								2010, I also taught Practical Translation to undergraduate and
								postgraduate students at Aston University in Birmingham.
							</p>
							<p>
								After completing my Master’s degree in translation, in 2007, I
								launched my career as a full-time translator offering
								Italian-English translations, French-English translations and
								Spanish-English translations into English.
							</p>
							<p>
								Over the years my business has evolved to offer translation,
								proofreading, transcreation and copywriting services. As a
								qualified, certified translator I am a member of the Institute
								of Translation and Interpreting.
							</p>
							<p>
								In my work as a professional translator and copywriter I have
								worked on a variety of advertising texts and digital content,
								including website copy, for companies in a variety of industries
								ranging from medical, hospitality, construction, tourism,
								insurance, legal, to environment, beauty, aviation, and many
								more besides.
							</p>
							<p>
								This long-term exposure to a variety of sectors has enabled me
								to fine-tune my writing skills to strike the right tone of voice
								and register for my clients’ website copywriting or translation
								requirements. I always ensure I research the correct terminology
								for each piece of work I undertake so that the text of the
								marketing copy has the correct tone of voice for its readership.
							</p>
							<p>
								My extensive translation experience has enabled me to focus on
								finding the right word and ensure that my work reads as if it
								had originally been written in English, which is why I am able
								to provide copy and create content that is crisp, captivating
								and persuasive.
							</p>
							<p>
								In my free time, I enjoy walking in the South Downs, listening
								to audiobooks and travelling. I also love spending quality time
								with my family and my cats Borge and MNA{" "}
								{`(there’s a long story behind both of their
							names, you can ask me about it sometime)`}
								.
							</p>
						</>
					}
				/>
			</section>
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default AboutPage;
