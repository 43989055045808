import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const AboutPhotoRight = ({ title, text, image, imgAlt }) => {
	return (
		<>
			<GatsbyImage className="d-md-none" image={image} alt={imgAlt} />
			<div Tag="section" className=" position-relative mb-3 mb-md-6 mb-lg-7 ">
				<div
					style={{ zIndex: "-3" }}
					className="bg-light-background top-0 h-100 w-100 d-md-none position-absolute"
				></div>
				<Container className="pt-4">
					<Row className=" pt-3 px-md-3 ">
						<Col md={11} lg={9}>
							<div className="position-relative py-md-6 ">
								<div
									style={{ zIndex: "-3" }}
									className="bg-light-background d-none d-md-block top-0 h-100 w-100 position-absolute"
								></div>

								<div className="position-absolute d-none d-md-block top-20 end--20 translate-middle-y">
									<GatsbyImage
										style={{ maxWidth: "15rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>

								<Col
									md={{ span: 8, offset: 1 }}
									className="pl-0 px-2 pe-md-0 pb-3 pb-md-0 ps-md-3"
								>
									<h2 className="pt-3 mb-3" id="services-hero-title">
										{title}
									</h2>
									<hr
										className="bg-primary"
										style={{ height: "2px", width: "250px", opacity: "1" }}
									/>

									<p className="mb-lg-0 fw-light">{text}</p>
								</Col>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default AboutPhotoRight;
